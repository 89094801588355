import React, { useState } from 'react';
import './App.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import {useAuthState} from 'react-firebase-hooks/auth';
import {useCollectionData} from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyAEC_Fy8FYpsnh_j4gAHgKPtAAVnvsFcnE",
  authDomain: "notes-project-c7e93.firebaseapp.com",
  projectId: "notes-project-c7e93",
  storageBucket: "notes-project-c7e93.appspot.com",
  messagingSenderId: "597722308972",
  appId: "1:597722308972:web:81e42997702c5d0673d349",
  measurementId: "G-FTB70YKGND"
})

const auth = firebase.auth();
const firestore = firebase.firestore();

function App() {

  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header className="App-header">
        <h1>hello</h1>
        <SignOut/>
      </header>

      <section>
        {user ? <Board/> : <Signin/>}
        
      </section>




    </div>
  );
}

function Board() {

  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt','desc').limit(5);

  const [messages] = useCollectionData(query, {idField: 'id'});

  const [formValue,setFormValue] = useState('');

  const sendMessage = async(e) => {
    e.preventDefault();

    if(formValue === "") {console.log("no content to submit");return}
    const {uid} = auth.currentUser;

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
    })

    setFormValue('');
  }

  return (
    <>
      <div> 
        {messages && messages.map(msg => <Post key={msg.id} message={msg} />)}
      </div>

      <form onSubmit={sendMessage}> 
        <input value={formValue} onChange={(e) => setFormValue(e.target.value)} />
        <button type='submit'>sub</button>
      </form>


    </>
  )

}

function Post(props) {
  const {text, uid} = props.message;
  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'recieved';
  return (
    <div className={`message ${messageClass}`}>

      <p>{text}</p>
    </div>
  
  
  
  )
}

function Signin() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
    const uname = auth.currentUser;
    console.log(uname);
  }

  return(
    <>
      <button className='sign-in' onClick={signInWithGoogle}>Sign in with Google</button>
      <p>plz dont be bad</p>
    </>
  )

}

function SignOut() {
  return auth.currentUser && (
    <button className='sign-out' onClick={() => auth.signOut()}>Sign Out</button>
  )
}



export default App;
